.form-item {
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
}

.form-item__label {
  display: block;
  font-family: 'Italian-regular';
}

.form-item__input,
.form-item__select {
  margin-top: 0.4rem;
}

.form-item__select {
  width: 100%;
}

.form-group.field-object.inline-fields fieldset {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.form-group.field-object.inline-fields fieldset .form-group {
  margin-right: 2rem;
}

.form-group input {
  min-width: 300px;
}

.form-group .checkbox label {
  padding-left: 0;
}

.array-item-add {
  margin-left: 0;
}
