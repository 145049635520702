@import '../../styles/variables';

.select {
  min-width: 120px;
  font-family: 'Italian-bold', Arial, Helvetica, sans-serif;
  cursor: pointer;
  z-index: 2;
  margin-top: 8px;

  .select__control {
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 0;
  }

  .select__control--is-focused,
  .select__control:hover {
    border: 1px solid $color-secondary;
    transition: color 0.2s, background-color 0.1s;
  }

  .select__option--is-focused {
    background: $color-secondary;
    color: white;
    transition: none;
  }

  .select__option {
    cursor: pointer;
  }

  .select__option--is-active {
    color: $color-secondary;
    cursor: initial;
  }
  .select__option--is-selected {
    color: $color-secondary;
    background-color: white;
    cursor: initial;
  }

  .select__menu {
    z-index: 31;
    border-radius: 0;
    top: calc(100% - 7px);
    .select__menu-list {
      padding: 0;
      border-radius: 0;
    }
  }

  &__multi-value {
    border-radius: 0 !important;
    &__remove {
      &:hover {
        color: white !important;
        background-color: rgba($color-primary, 1) !important;
      }
    }
  }

  .select__multi-value__remove {
    width: 20px;
  }

  .select__clear-indicator {
    width: 50px;
  }

  .select__dropdown-indicator {
    width: 50px;
  }
}
