@font-face {
  font-family: 'Italian-regular';
  src: local('Italian-regular'),
    url('../fonts/ItalianPlateNo2Expanded-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Italian-bold';
  src: local('Italian-bold'),
    url('../fonts/ItalianPlateNo2Expanded-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'ItalianBlackItalic';
  src: local('Italian-bold'),
    url('../fonts/ItalianPlateNo2-BlackItalic.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: 'Italian-regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3 {
  font-family: 'ItalianBlackItalic';
  line-height: 1.4;
}
h4,
h5,
h6 {
  font-family: 'Italian-bold';
  margin: 0;
  line-height: 1.4;
}

h1 {
  font-weight: 800;
}
h2 {
  font-weight: 800;
}

// Material typography overwrites

.MuiTypography-root {
  font-family: 'Italian-regular';
}
