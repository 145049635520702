.toggle {
  width: 100%;
  display: inline-flex;
  align-items: center;
}

.toggle--right {
  justify-content: flex-start;
}

.toggle--left {
  justify-content: flex-end;
}

.toggle__label {
  font-weight: 500;
}

.toggle__label--before {
  margin-right: 5px;
}

.toggle__label--after {
  margin-left: 5px;
}

.toggle label {
  margin: 0;
}
