.spinner {
  z-index: 5000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner .backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #0000001c;
  z-index: -1;
  animation-name: backdropenter;
  animation-duration: 0.2s;
  transform-origin: center;
  animation-fill-mode: forwards;
  opacity: 0;
}

svg {
  width: 300px;
  animation-name: spinnerenter;
  animation-duration: 0.3s;
  transform-origin: center;
  animation-fill-mode: forwards;
}

svg .plane-wrapper {
  animation-name: rotateplane;
  animation-duration: 1s;
  transform-origin: center;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

svg .globe-wrapper {
  animation-name: scaleglobe;
  animation-duration: 2s;
  transform-origin: center;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

svg .cls-1,
svg .cls-3 {
  fill: none;
}

svg .cls-2 {
  fill: #ff3900;
}
svg .cls-3 {
  stroke: #ff3900;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
svg .cls-4 {
  fill: #fff;
}

@keyframes backdropenter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spinnerenter {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes rotateplane {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.7);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
@keyframes scaleglobe {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(0.65);
  }
  100% {
    transform: scale(0.7);
  }
}
