@import './variables';

.login-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, #ff5303 0%, #ff3c00 50%, #ff3c00 80%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--white {
    background: white;
  }

  &__logo {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 100px;
  }

  h1 {
    font-size: 2rem;
    font-weight: 700;
    color: white;
    line-height: 1;
    margin-bottom: 2em;
  }
}
