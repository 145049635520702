@import './styles/variables';
@import './styles/typography';
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.app__header__logo {
  height: 20px;
}

.app__content {
  height: 100%;
  width: 100%;
}

.user {
  float: right;
}

.user__name {
  margin-left: 10px;
}

.content {
  position: relative;
  left: 200px;
  width: calc(100vw - 240px);
}

.content__navigation {
  display: flex;
  margin-bottom: 30px;
}

.content--full-width {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  > div.MuiPaper-root {
    height: 100%;
  }
}

.content--full-width .table__cell .table__cell {
  padding: 0;
}

/* TOOLTIPS */
.tooltip--table-detail.show {
  padding-top: 32px;
  opacity: 1;
}

//Inputs
.MuiInput-underline {
  padding-bottom: 0 !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid $color-secondary !important;
}
