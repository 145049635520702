@import '../../styles/variables';

.MuiGrid-root.MuiGrid-container {
  margin-left: 0 !important;
  width: 100% !important;
}

.editor--compact .field-description {
  display: none;
}
.editor--compact .MuiTypography-subtitle2 {
  display: none;
}

.editor--compact .MuiTypography-caption {
  display: none;
}

.editor--compact .enum-widget + .MuiTypography-caption {
  display: none;
}

.editor--modal {
  > .MuiFormControl-root {
    > .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
      box-shadow: none !important;
      box-shadow: none !important;
    }
  }
}
