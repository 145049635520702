.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 14px;
}

.switch__input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  border-radius: 34px;
}

.switch__slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: 2px;
  top: 50%;
  background-color: #ff3c00;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  border-radius: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  box-shadow: 1px 1px 3px 1px #ff3d0029;
}

.switch__input:checked + .switch__slider {
  background-color: #ff3c007a;
}

.switch__input:focus + .switch__slider {
  box-shadow: 0 0 1px #ff3c00;
}

.switch__input:checked + .switch__slider:before {
  -webkit-transform: translate(14px, -50%);
  -ms-transform: translate(14px, -50%);
  transform: translate(14px, -50%);
}
