$spacing: 8px;

@function spacing($value) {
  @return $value * $spacing;
}

$color-primary: #ff3c00;
$color-primary-hover: #d63301;
$color-secondary: #004b99;
$color-text: #333;
$color-blue: #dae5f0;
$color-grey-light: #f5f5f5;
