@import '../../styles/variables';

/* TABLE STYLES  */

.mt-MuiTable-root {
  border: 1px solid #ddd;
}

.mt-MuiPaper-rounded {
  border-radius: 0;
  box-shadow: none !important;
}

.mt-MuiTableCell-head {
  min-width: 120px;
  font-size: 12px;
  border: 1px solid rgba(224, 224, 224, 1);
}

.mt-MuiInput-root {
  position: relative;
  padding: 6px 0px;
  font-size: 16px;
}

.mt-MuiToolbar-root {
  align-items: flex-end !important;
  align-content: flex-end;
  .mt-MuiSvgIcon-root {
    font-size: 20px;
    fill: rgba(0, 0, 0, 0.87) !important;
  }
  > div {
    &:first-of-type {
      display: none;
    }
  }
}

.mt-MuiToolbar-regular {
  min-height: initial !important;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.mt-MuiToolbar-regular div:nth-child(1) {
  order: 0;
}
.mt-MuiToolbar-regular div:nth-child(2) {
  position: absolute;
}
.mt-MuiToolbar-regular div:nth-child(3) {
  order: 3;
  height: 100%;
}
.mt-MuiToolbar-regular div:nth-child(4) {
  order: 2;
}

.mt-MuiToolbar-root.mt-MuiToolbar-regular.mt-MuiToolbar-gutters {
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  color: currentColor;

  > div {
    .mt-MuiTypography-caption.mt-MuiTypography-root {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

.mt-MuiTablePagination-toolbar {
  margin: 0;
}

.mt-MuiTableCell-root {
  vertical-align: baseline;
}

.mt-MuiTableCell-root .render-airlines {
  background-color: #e5e5e5;
  border-radius: 0;
  margin-top: 2px;
  padding: 1px 4px 3px 4px;
}

.mt-MuiTableCell-root .render-airlines__trumpet {
  font-size: 8px;
  line-height: 1em;
  margin-top: 3px;
  margin-bottom: 2px;
  text-transform: upperCase;
}

.mt-MuiTableRow-root[level='1'] td button {
  display: none;
}

.mt-MuiTableRow-root[level='1'] td {
  position: relative;
}

.mt-MuiTableRow-root[level='1'] td:first-of-type:after {
  margin-left: 1rem;
  content: 'Exception';
  font-size: 12px;
  display: block;
  font-weight: 600;
}

.mt-MuiTableRow-root {
  &.mt-MuiTableRow-head {
    background-color: white;
    z-index: 1;
  }
}

.mt-MuiTableCell-root .mt-MuiFormControl-root .mt-MuiInputBase-root {
  font-size: 13px;
}

.mt-MuiInputBase-root .mt-MuiIcon-fontSizeSmall {
  font-size: 2rem;
}

.mt-MuiTableCell-paddingCheckbox {
  max-width: 40px;
  min-width: initial;
}

.mt-MuiTableRow-root:nth-child(even) {
  background-color: #f9f9f9;
}

.inline {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}

.inline strong {
  margin-right: 5px;
}

.mt-MuiIcon-root {
  font-size: 0.8em;
  fill: rgba(0, 0, 0, 0.87) !important;
}

.input-label {
  display: flex;
  justify-content: space-between;
  max-width: 200px;
}

strong {
  text-transform: capitalize;
}
