@import '../../styles/variables';

.modal {
  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 2px 5px 0px #000000;
  }

  &__modal {
    position: relative;
    background-color: #ffffff;
    top: 0;
    max-height: calc(100vh - spacing(24));
    max-width: 90%;
    max-width: 1000px;
    box-sizing: border-box;
    overflow: hidden;
    padding: spacing(8) 0 spacing(2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__modal-form {
    width: 90%;
    height: calc(100vh - spacing(12.5));
  }

  &__content {
    padding: spacing(2);
    overflow: auto;
    flex-grow: 1;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: spacing(1) spacing(2);
  }

  &__header svg {
    cursor: pointer;
    transition: color 200ms;
    width: spacing(4);
    height: spacing(4);
    z-index: 4;
    &:hover {
      color: $color-primary;
    }
  }

  &--editor .editor--modal {
    button[type='submit'] {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: spacing(1.5);
      bottom: initial;
      z-index: 5;
      font-weight: 600;
    }
  }
}
